<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-02-28 21:24:37
 * @LastEditTime: 2023-03-17 15:02:54
 * @Descripttion: 错题集【科目】
-->
<style lang="scss" scoped>
.wrong-questions {
    &--subject {
        @include innerPage(40px 16px 48px 15px);
        @include pageTitle(0);
        @include pageFoot;

        .search-form {
            width: 74.4%;
            margin-left: auto;
            margin-right: 100px;

            .el-form {
                min-width: 700px;
                margin-left: 20px;
            }

            .el-form,
            .el-form-item {
                flex-grow: 1;
            }

            .el-form-item {
                min-width: 200px;
            }
        }
    }

    &--total {
        text-align: center;
        color: #333;
        line-height: 42px;
        height: 42px;
        margin-top: 56px;

        span {
            font-size: 26px;
        }
    }

    &-wrapper {
        width: 85.44%;
        height: calc(100% - 184px);
        margin: 40px auto 0;

        .custom-foot {
            border-top: none;
        }
    }

    &-box {
        width: calc(100% + 20px);
        height: calc(100% - 74px);
        position: relative;

        &.no-data {
            width: 100%;
            background: #fff;
            border-radius: 10px;
        }
    }

    &-list {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-right: 20px;
        overflow: hidden;
        overflow-y: auto;

        .question {
            width: 100%;
            background: #eef0f8;
            border-radius: 10px;
            box-sizing: border-box;
            padding: 30px 48px 0;
            margin-top: 36px;
            @include flexBox;
            align-items: baseline;
            flex-wrap: wrap;

            &:first-child {
                margin-top: 0;
            }

            &--num,
            &--type {
                flex-shrink: 0;
                line-height: 24px;
                flex-shrink: 0;
            }

            &--num {
                color: #1f1f1f;
                font-size: 16px;
            }

            &--type {
                min-width: 56px;
                height: 24px;
                background: #6340c8;
                border-radius: 12px 0px 12px 12px;
                text-align: center;
                color: #fff;
                margin: 0 14px 0 4px;
                box-sizing: border-box;
                padding: 0 4px;
            }


            &--data {
                flex-grow: 1;
                max-width: calc(100% - 120px);

                h5 {
                    line-height: 24px;
                    color: #1f1f1f;
                }

                .imgs-group {
                    margin-top: 16px;
                    @include flexBox;

                    .el-image {
                        width: 130px;
                        height: 78px;
                        border-radius: 6px;
                        margin-right: 10px;
                    }
                }

                .options {
                    margin-top: 14px;

                    li {
                        line-height: 30px;
                        color: #666666;

                        .imgs-group {
                            margin-top: 0;
                            margin-bottom: 16px;
                            box-sizing: border-box;
                            padding-left: 18px;
                        }
                    }
                }
            }

            &-condition {
                width: 100%;
                height: 72px;
                box-sizing: border-box;
                border-top: 1px solid #dee0e7;
                color: #666;
                margin-top: 20px;
                @include flexBox;

                p {
                    width: 120px;

                    &:nth-child(2) {
                        text-align: right;
                        position: relative;

                        &::before {
                            content: '';
                            height: 16px;
                            border-left: 1px solid #d6d7de;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }

                .danger,
                .success {
                    font-size: 20px;
                }

                .danger {
                    color: #f83d3d;
                }

                .success {
                    color: #2ac293;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .compose {
            padding-bottom: 40px;

            h5 {
                @include flexBox;
                align-items: baseline;

                span.bold {
                    display: inline-block;
                    flex-grow: 1;
                    margin-right: 20px;
                }

                .collect {
                    margin-right: 34px;
                }

                .iconfont {
                    margin-top: 3px;
                }

                .el-button {
                    margin-top: -7px;

                    &.is-circle {
                        margin-top: -4px;
                    }
                }
            }

            .subtopic {
                margin-top: 20px;

                &-item {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 40px 30px 0;
                    background: #e8eaf3;
                    border-radius: 10px;
                    color: #666;
                    margin-bottom: 24px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &--title {
                    font-size: 16px;
                    line-height: 24px;
                }

                &--options {
                    box-sizing: border-box;
                    padding: 0 40px;
                    margin-top: 16px;

                    .option {
                        line-height: 30px;

                        .imgs-group {
                            margin-top: 0;
                            margin-bottom: 16px;
                            box-sizing: border-box;
                            padding-left: 18px;
                        }
                    }
                }
            }
        }
    }
}
</style>

<template>
    <section class="wrong-questions--subject">
        <div class="page-title">
            <h3>{{ $route.params.subject }}错题集</h3>
            <div class="search-form">
                <el-form inline :model="searchForm">
                    <el-form-item>
                        <el-select v-model="searchForm.syque_typ_id" placeholder="选择题目类型">
                            <el-option label="全部" :value="0" />
                            <el-option v-for="item  in questionType" :key="item.syque_typ_id"
                                :label="item.syque_typ_name" :value="item.syque_typ_id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="searchForm.stque_difficulty" placeholder="选择难易程度">
                            <el-option label="全部" :value="0" />
                            <el-option label="简单" :value="10" />
                            <el-option label="一般" :value="20" />
                            <el-option label="困难" :value="30" />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="searchForm.stque_status" placeholder="选择掌握程度">
                            <el-option label="全部" :value="0" />
                            <el-option label="未做过" :value="10" />
                            <el-option label="已做过" :value="20" />
                            <el-option label="已掌握" :value="30" />
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-button type="custom_primary" size="medium" @click="searchData">查询</el-button>
            </div>
            <el-button type="custom_primary" size="medium" @click="$router.go(-1)">
                返回
            </el-button>
        </div>
        <h5 class="wrong-questions--total bold">当前总数：<span class="pf_bold">{{ listPage.total || 0 }}</span>道题</h5>
        <div class="wrong-questions-wrapper">
            <div class="wrong-questions-box" :class="{ 'no-data': $isEmpty(listData) }" v-loading="dataLoad">
                <ul class="wrong-questions-list" v-if="!$isEmpty(listData)">
                    <li class="question" :class="{ compose: isCompose(que.stque_ismakeup) }"
                        v-for="(que, que_index) in listData" :key="que.stque_id">
                        <span class="question--num pf_bold">
                            {{ queNum(que_index + 1) }}.
                        </span>
                        <span class="question--type">{{ que.syque_typ_name }}</span>
                        <div class="question--data">
                            <!-- 组合题 -->
                            <template v-if="isCompose(que.stque_ismakeup)">
                                <h5 class="bold">
                                    <!-- {{ que.stque_title }} -->
                                    <span v-html="que.stque_title" class="richblock"></span>
                                </h5>
                                <div class="imgs-group" v-if="que.stque_image">
                                    <el-image fit="scale-down" :src="formatfile(que.stque_image)"
                                        :preview-src-list="[formatfile(que.stque_image)]" />
                                </div>
                                <ol class="subtopic">
                                    <li class="subtopic-item" v-for="(sque, sque_index) in que.children_question"
                                        :key="sque.stque_id">
                                        <p class="subtopic--title" style="display: flex;flex-wrap: wrap;">
                                            （{{ sque_index + 1 }}）【{{ sque.syque_typ_name }}】
                                            <!-- {{ sque.stque_title }} -->
                                            <span class="richinline" v-html="sque.stque_title"></span>
                                        </p>
                                        <div class="imgs-group" v-if="sque.stque_stuimages">
                                            <el-image fit="scale-down" :src="formatfile(sque.stque_stuimages)"
                                                :preview-src-list="[formatfile(sque.stque_stuimages)]" />
                                        </div>
                                        <dl class="subtopic--options" v-if="isChoice(sque.syque_typ_id)">
                                            <dt class="option" v-for="opt in sque.question_child_item"
                                                :key="opt.stque_ite_id">
                                                <!-- {{ opt.stque_ite_code }}. {{ opt.stque_ite_title }} -->
                                                {{ opt.stque_ite_code }}.<span v-html="opt.stque_ite_title" class="richinline"></span>
                                                <div class="imgs-group" v-if="opt.stque_ite_image">
                                                    <el-image fit="scale-down" :src="formatfile(opt.stque_ite_image)"
                                                        :preview-src-list="[formatfile(opt.stque_ite_image)]" />
                                                </div>
                                            </dt>
                                        </dl>
                                        <div class="question-condition">
                                            <p>答错次数<b class="pf_bold danger">{{ sque.stque_errornum || 0 }}</b>次</p>
                                            <p>答对次数<b class="pf_bold success">{{ sque.stque_correctnum || 0 }}</b>次</p>
                                        </div>
                                    </li>
                                </ol>
                            </template>
                            <!-- 其他题型 -->
                            <template v-if="!isCompose(que.stque_ismakeup)">
                                <h5 class="bold">
                                    <!-- {{ que.stque_title }} -->
                                    <span v-html="que.stque_title" class="richblock"></span>
                                </h5>
                                <div class="imgs-group" v-if="que.stque_image">
                                    <el-image fit="scale-down" :src="formatfile(que.stque_image)"
                                        :preview-src-list="[formatfile(que.stque_image)]" />
                                </div>
                                <ul class="options" v-if="isChoice(que.syque_typ_id)">
                                    <li v-for="opt in que.children_question" :key="opt.stque_ite_id">

                                        <!-- {{ opt.stque_ite_code }}. {{ opt.stque_ite_title }} -->
                                        {{ opt.stque_ite_code }}.<span v-html="opt.stque_ite_title"
                                            class="richinline"></span>
                                        <div class="imgs-group" v-if="opt.stque_ite_image">
                                            <el-image fit="scale-down" :src="formatfile(opt.stque_ite_image)"
                                                :preview-src-list="[formatfile(opt.stque_ite_image)]" />
                                        </div>
                                    </li>
                                </ul>
                            </template>
                        </div>
                        <!-- 其他题型 -- 底部 -->
                        <div class="question-condition" v-if="!isCompose(que.stque_ismakeup)">
                            <p>答错次数<b class="pf_bold danger">{{ que.stque_errornum || 0 }}</b>次</p>
                            <p>答对次数<b class="pf_bold success">{{ que.stque_correctnum || 0 }}</b>次</p>
                        </div>
                    </li>
                </ul>
                <div class="no-data--empty" v-if="$isEmpty(listData) && !dataLoad">
                    <img src="@assets/images/no-data3.png" alt="">
                    <p>暂无错题数据哦~</p>
                </div>
            </div>
            <!-- 分页控件 -->
            <div class="custom-foot">
                <customPagination :current='listPage.pageIndex' :total="listPage.total" @pageChange="flippingPage" />
            </div>
        </div>
    </section>
</template>

<script>
import { $wrongQues } from "@api/resources"
import { mapState } from "vuex"
import { formatFile } from "@utils";
import { PAGE_SIZE } from "@config";
import customPagination from "@comp/customPagination"
export default {
    name: 'resources_wrongQuestionsSubject',
    components: { customPagination },
    computed: {
        ...mapState('common', ['questionType']),
        formatfile() {
            return function (url) {
                return formatFile(url)
            }
        },
        /** 判断是否是选择题 */
        isChoice() {
            const choiceTypeIds = [1, 2, 5];
            return function (val) {
                return choiceTypeIds.indexOf(val) != -1;
            };
        },
        /** 判断是否是组合题 */
        isCompose() {
            return function (val) {
                return val == 10;
            };
        },
        queNum() {
            return function (val) {
                let antecedent = (this.listPage.pageIndex - 1) * (this.listPage.size || PAGE_SIZE);
                let num = val + antecedent;
                num = num > 9 && num || `0${num}`
                return num;
            };
        },
    },
    data() {
        return {
            searchForm: {
                syque_typ_id: 0,
                stque_difficulty: 0,
                stque_status: 0,
            },
            listData: [], // 课件列表数据
            /* 表格分页参数 */
            listPage: {
                pageIndex: 1,
                total: 0
            },
            dataLoad: false,
        }
    },
    created() {
        this.getData();
    },
    methods: {
        /** 获取错题列表 */
        async getData() {
            this.dataLoad = true;
            let { syque_typ_id, stque_difficulty, stque_status } = this.searchForm;
            let params = {
                sysub_id: this.$route.params.id,
                pageindex: this.listPage.pageIndex,
            }
            if (syque_typ_id) params.syque_typ_id = syque_typ_id;
            if (stque_difficulty) params.stque_difficulty = stque_difficulty;
            if (stque_status) params.stque_status = stque_status;

            let { data: res } = await $wrongQues(params);
            this.dataLoad = false;
            this.listPage.total = res.allcount;
            this.listData = res.data;
            this.$forceUpdate();
        },
        /** 翻页 */
        flippingPage(val) {
            this.listPage.pageIndex = val;
            this.getData();
        },
        /** 筛选数据 */
        searchData() {
            this.listData = [];
            this.listPage.pageIndex = 1;
            this.getData();
        },
    },
}
</script>